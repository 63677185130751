import React from "react";
import Rellax from "rellax";
import { Container, Row, Col, Card, CardHeader } from "reactstrap";

function Pagos() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    if (window.innerWidth >= 991) {
      setTimeout(() => {
        new Rellax(".rellax", { center: true });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");
      script.parentNode.removeChild(script);
    };
  });

  async function valor() {
    var centavos = document.getElementById("amount-in-cents").value + "00";
    document.getElementById("amount-in-cents").value = centavos;
    var tipoDoc = document.getElementById("tipoDoc").value;
    document.getElementById("customer-data:legal-id-type").value = tipoDoc;
    const referencia = document.getElementById("reference").value;
    const moneda = document.getElementById("currency").value;
    const integrity = "prod_integrity_Yjch0EXT93hQjleGOp5AuZeem7htxScu";
    const cadenaConcatenada = referencia + centavos + moneda + integrity;
    const encodedText = new TextEncoder().encode(cadenaConcatenada);
    const hashBuffer = await crypto.subtle.digest("SHA-256", encodedText);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    document.getElementById("signature").value = hashHex;
    console.log(document.getElementById("signature").value);

    // Aquí se puede realizar el envío de datos
    enviarDatos();
  }

  function enviarDatos() {
    const data = {
      publicKey: document.getElementById("key").value,
      signature: document.getElementById("signature").value,
      tipoDoc: document.getElementById("customer-data:legal-id-type").value,
      legalId: document.getElementById("customer-data:legal-id").value,
      currency: document.getElementById("currency").value,
      amountInCents: document.getElementById("amount-in-cents").value,
      reference: document.getElementById("reference").value,
      fullName: document.querySelector("input[name='customer-data:full-name']").value,
      phoneNumber: document.getElementById("phone-number").value,
      email: document.getElementById("email").value,
    };

    // Ejemplo de cómo enviar los datos con fetch
    fetch("https://checkout.wompi.co/p/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Éxito:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <>
      <div className="wrapper">
        <div id="servicio" style={{ backgroundColor: "#f9f9f9" }}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="title text-center">
                  <b style={{ color: "#ef7e2d" }}>Pasarela de pagos</b>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" lg="6">
                <Card style={{ backgroundColor: "white" }}>
                  <CardHeader>
                    <h4 className="info-title text-center" style={{ marginTop: "20px", color: "black" }}>
                      <b>Ingrese el valor exacto del servicio y de click en pagar para ir a la pasarela de pagos de WOMPI</b>
                    </h4>
                  </CardHeader>
                </Card>
              </Col>
              <Col className="ml-auto mr-auto" lg="6">
                <Card style={{ backgroundColor: "white" }}>
                  <CardHeader style={{ textAlign: "center" }}>
                    <img
                      alt="Imagen con referencia hacia un icono para descargar un archivo PNG"
                      width="80px"
                      src={require("assets/img/NOTARIA/WompiLogo.png")}
                    />
                    <form action="https://checkout.wompi.co/p/" method="GET">
                      <input type="hidden" name="public-key" id="key" value="pub_prod_9yIOiS2rt1L7qxQvX54JUcaygvhgh0xP" />
                      <input type="hidden" name="redirect-url" value="https://www.notaria26bogota.com.co/pagos" />
                      <label style={{ color: "orange" }}>Tipo de Documento</label><br></br>
                      <select id="tipoDoc">
                        <option>Seleccione Tipo de Documento</option>
                        <option value="CC">Cédula de ciudadanía</option>
                        <option value="CE">Cédula de Extranjería</option>
                        <option value="NIT">NIT</option>
                      </select><br></br>
                      <input type="hidden" name="customer-data:legal-id-type" id="customer-data:legal-id-type" />
                      <label style={{ color: "orange" }}>Documento de identificación</label><br></br>
                      <input type="text" name="customer-data:legal-id" id="customer-data:legal-id" required /><br></br>
                      <input type="hidden" name="currency" id="currency" value="COP" />
                      <label style={{ color: "orange" }}>Valor del Servicio</label><br></br>
                      <input type="text" name="amount-in-cents" id="amount-in-cents" required /><br></br>
                      <label style={{ color: "orange" }}>Código de Referencia</label><br></br>
                      <input type="text" name="reference" id="reference" required /><br></br>
                      <label style={{ color: "orange" }}>Nombre Completo</label><br></br>
                      <input type="text" name="customer-data:full-name" required /><br></br>
                      <label style={{ color: "orange" }}>Número Teléfono</label><br></br>
                      <input type="text" name="customer-data:phone-number" id="phone-number" required /><br></br>
                      <label style={{ color: "orange" }}>Correo Electrónico</label><br></br>
                      <input type="text" name="customer-data:email" id="email" required /><br></br>
                      <button onClick={valor} style={{ color: "orange", fontWeight: "bold" }}>Pagar Con Wompi</button><br></br>
                      <input type="hidden" name="signature:integrity" id="signature"/><br></br>
                    </form>
                    
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Pagos;
import React from "react";
import Iframe from 'react-iframe';
import { useSelector } from "react-redux";
import Axios from "axios";

// reactstrap components
import {
  Alert,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Contactenos() {

  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [nombres, setNombres] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState({ mostrar: false, tipo: 'danger', message: '' });

  const notaria = useSelector((state) => state.notaria.notaria);

  const enviarCorreo = async (e) => {
    e.preventDefault()

    if (checked && nombres !== '' && correo !== '' && mensaje !== '') {
      try {
        let config = {
          method: 'POST',
          mode: 'cors',
          headers: { "Access-Control-Allow-Origin": true, Authorization: "Bearer " + process.env.REACT_APP_TOKEN_API },
        }
        let data = {
          'remitente_name': nombres,
          'remitente_email': correo,
          'descripcion': mensaje,
          'destinatario': notaria.email_contactenos
        }
        const resultado = await Axios.post(process.env.REACT_APP_UrlApi + 'MailContact', data, config)

        if (resultado.status === 200) {
          setNombres('')
          setCorreo('')
          setMensaje('')
          setErrorEmail({ mostrar: true, tipo: 'success', message: 'Correo Enviado, pronto le daremos una respuesta' })
        } else {
          setErrorEmail({ mostrar: true, tipo: 'danger', message: 'Sucedió un error, intentarlo mas tarde' })
        }
      }
      catch (eroror) {
        setErrorEmail({ mostrar: true, tipo: 'danger', message: 'Sucedió un error, intentarlo mas tarde' })
      }
    } else {
      setErrorEmail({ mostrar: true, tipo: 'danger', message: 'Campos vacíos' })
      setTimeout(() => {
        setErrorEmail({ mostrar: false, tipo: 'danger', message: '' })
      }, 3000)
    }

  }

  return (
    <>
      <div className="section" style={{ backgroundColor: "#f6f6f6" }} id="contacto">
        <Container>
          <Row>
            <Col md="12">
              <h1 className="title text-center"><b style={{ color: "#ef7e2d" }}>Información</b> de contacto</h1>
              <h5 className="description text-justify" style={{ color: "Black" }}>
                <b>Necesita más información? Visite nuestras oficinas o comuníquese con nuestras
                  líneas de atención al cliente para recibir información adicional.</b>
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <CardBody>
                  <h4 className="info-title text-center"><b>Contáctenos por Correo electrónico</b></h4>
                  <Form id="contact-form" method="post" role="form">
                    <label>Nombre</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >

                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                      <Input
                        placeholder="Nombre completo"
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                        value={nombres}
                        onChange={(e) => setNombres(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <label>Correo electrónico</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                      <Input
                        placeholder="example@email.com"
                        type="email"
                        value={correo}
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={(e) => setCorreo(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label> Mensaje</label>
                      <Input
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                        value={mensaje}
                        onChange={(e) => setMensaje(e.target.value)}
                      ></Input>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={(e) => setChecked(true)}
                        ></Input>
                        <span className="form-check-sign"></span>
                        No soy un robot
                      </Label>
                    </FormGroup>
                    {errorEmail.mostrar &&
                      <Row className="justify-content-center" id="error">
                        <Col ms="6">
                          <Alert color={errorEmail.tipo}>
                            <b style={{ fontSize: "16px" }}>{errorEmail.message}</b>
                          </Alert>
                        </Col>
                      </Row>
                    }
                    <div className="submit text-center">
                      <Button
                        color="warning"
                        className="btn-raised btn-round"
                        defaultValue="Enviar"
                        //type="submit"
                        onClick={(e) => enviarCorreo(e)}
                      >
                        <b style={{ fontSize: "18px" }}>Enviar Mensaje</b>
                      </Button>
                    </div>
                  </Form>

                  <h4 className="info-title"><b style={{ color: "#ef7e2d" }}>Correo Institucional:</b> notaria26bogota@ucnc.com.co</h4>
                  <h4 className="info-title"><b style={{ color: "#ef7e2d" }}>Notificaciones Judiciales:</b><br /> notificacionesjudiciales@notaria26bogota.com<br />
                    <font size={1}>*Este correo es exclusivo para notificaciones judiciales, por favor no lo utilice para otros efectos</font></h4>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" className="justify-content-center">
              <Card>
                <CardBody>
                  <div className="info-horizontal">
                    <div className="icon icon-primary">
                      <i className="now-ui-icons location_pin"></i>
                    </div>
                    <div className="description">
                      <b>Contador de Visitas</b><br></br>
                      <a href="https://www.contadorvisitasgratis.com" title="contador de visitas para blogger"><img src="https://counter8.optistats.ovh/private/contadorvisitasgratis.php?c=j8sgxnekf8juk1mh5qcghley2q22zjbw" border="0" title="contador de visitas para blogger" alt="contador de visitas para blogger"/></a>
                      <h4 className="info-title">Estamos ubicados</h4>
                      <p style={{ color: "Black" }}><b>
                        Carrera 12 #93-26 Bogotá, Cundinamarca<br></br>
                        Correo físico: Carrera 12 #93-26 Bogotá, Cundinamarca <br></br>

                        Lunes a Viernes <br></br>
                        8:00 am - 5:00 pm <br></br>
                        <b style={{ color: "#ef7e2d" }}>Jornada continua</b> <br></br>
                        Sábados de turno 2024<br></br>
                        30 de Noviembre<br></br>
                      </b></p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Row>
                <Col md="6">
                  <Card>
                    <CardBody>
                      <div className="info-horizontal">
                        <div className="icon icon-primary">
                          <i className="now-ui-icons tech_mobile"></i>
                        </div>
                        <div className="description">
                          <h4 className="info-title">Teléfonos</h4>
                          <p style={{ color: "Black" }}><b>
                            <b style={{ color: "#ef7e2d" }}>PBX:</b> +57 (1) 7431431 <br></br>
                          </b></p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <CardBody>
                      <h4 className="info-title" style={{ marginTop: "11px" }}>Atención al público:</h4>
                      <center>
                        <a href="/peticiones" className="btn btn-warning" aria-disabled="true" role="button">
                          <b style={{ fontSize: "15px" }}> PQRS</b>
                        </a>
                      </center>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h2 className="title text-center"><b style={{ color: "#ef7e2d" }}>Ubicación</b></h2>
            </Col>
          </Row>
          <Row>
            <Col md="10">
              <Card>
                <CardBody>
                  <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7953.112230428174!2d-74.05360068786621!3d4.67293041565024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b7a6fc3ca48d423!2sNotaria%2026!5e0!3m2!1ses-419!2sco!4v1597617484933!5m2!1ses-419!2sco"
                    width="100%"
                    frameBorder="0"
                    height="40%"
                    className="text-center"
                    frameborder="0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0">
                  </Iframe>
                </CardBody>
              </Card>
            </Col>
            <Col md="2">
              <Card>
                <CardBody className="text-center">
                  <p style={{ fontSize: "22px" }}><b>Rutas</b></p>
                  <a type="button" href="https://www.waze.com/es/livemap/directions/colombia/bogota/bogota/notaria-26?place=ChIJJ-128o2aP44RI9RIysNvens&utm_campaign=waze_website&utm_expid=.K6QI8s_pTz6FfRdYRPpI3A.0&utm_referrer=&utm_source=waze_website" style={{ textDecoration: "none" }}>
                    <img src={require("assets/img/NOTARIA/waze.png")} alt="Logo de la aplicación de Waze" width="40%" style={{ textDecoration: "none" }} />
                  </a>
                  <br></br>
                  <a href="https://www.google.com/maps/dir/?api=1&destination=Notaria+26,+Cra.+12+%2393-26,+Bogotá" style={{ textDecoration: "none" }}>
                    <img src={require("assets/img/NOTARIA/google.png")} alt="Logo de la aplicación de Google" width="40%" style={{ textDecoration: "none" }} />
                  </a>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <b>Entidades, agremiaciones y asociaciones</b>
              <Row className="align-items-end">
              <Col ms="2">
                  <a href="https://www.vur.gov.co/" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                    <img
                      alt="Logo VUR"
                      src={require("assets/img/NOTARIA/vur.png")}
                    ></img>
                  </a>
                </Col>
                <Col ms="2">
                  <a href="http://www.minjusticia.gov.co/" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                    <img
                      alt="Logo de Ministero de Justicia y Derecho de Colombia"
                      src={require("assets/img/NOTARIA/justicia.png")}
                    ></img>
                  </a>
                </Col>
                <Col ms="2">
                  <a href="https://www.supernotariado.gov.co/" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                    <img
                      alt="Logo de SNR y Registro"
                      src={require("assets/img/NOTARIA/LOGO_SNR.png")}
                    ></img>
                  </a>
                </Col>
                <Col ms="2" className="text-center">
                  <a href="https://registraduria.gov.co/" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                    <img
                      alt="Logo de la Registraduria"
                      src={require("assets/img/NOTARIA/registraduria.png")}
                    ></img>
                  </a>
                </Col>
                <Col ms="2" className="text-center">
                  <a href="https://www.procuraduria.gov.co/" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                    <img
                      alt="Logo de Procuraduria Nacional"
                      src={require("assets/img/NOTARIA/procuraduria.jpg")} style={{ height: '80px' }}
                    ></img>
                  </a>
                </Col>
                <Col ms="2" className="text-center">
                  <a href="https://www.suin-juriscol.gov.co/" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                    <img
                      alt="Logo SUIN"
                      src={require("assets/img/NOTARIA/suin.png")} style={{ height: '80px' }}
                    ></img>
                  </a>
                </Col>
              </Row>
            </CardBody>
            <br></br>
            <CardBody>
              <b>Directorio</b>
              <Row className="align-items-end">
              <Col md="2" sm="3">
                  <a href="https://www.gobiernoenlinea.gov.co/" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                    <img
                      alt="Logo del Gobierno de Colombia"
                      src={require("assets/img/NOTARIA/gobierno.png")}
                    ></img>
                  </a>
                </Col>
                <Col md="2" sm="3" className="text-center">
                  <a href="https://www.ucnc.com.co/sitio/" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                    <img
                      alt="Logo de la Unión Colegiada del Notariado Colombiano"
                      src={require("assets/img/NOTARIA/notario.jpg")}
                    ></img>
                  </a>
                </Col>
                <Col md="2" sm="1" className="text-center">
                  <a href="https://unobog.com" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
                    <img
                      alt="Logo de Unión de Notarios de Bogotá D.C."
                      src={require("assets/img/NOTARIA/UNOBOG.jpg")} style={{ height: '80px' }}
                    ></img>
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br></br>
        </Container>
      </div>
    </>
  );
}

export default Contactenos;
